@import 'vendor';

.navbar-top {
  .nav-item{
    position: relative;

    .nav-link i {
      font-size: 24px;
    }
  }
  .notification {
    position: absolute;
    top: 0;
    left: 25px;
    font-size: 9px;
    background: $red;
    color: $white;
    min-width: 20px;
    padding: 0 5px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 19px;
    vertical-align: middle;
    display: block;
  }
}

#sidenav-collapse-main {
  .navbar-nav {
    .nav-item {
      position: relative;

      &:after {
        font-family: 'boxicons';
        font-weight: normal;
        font-style: normal;
        position: absolute;
        top: 13px;
        right: 10px;
        height: 20px;
        width: 20px;
        text-align: center;
        border-radius: 25px;
        font-size: 13px;
      }

      &.pending:after {
        background-color: $yellow;
        color: $white;
        content: "\EA21";
      }

      &.completed:after {
        content: "\EA0F";
        color: $green;
        border: 1px solid $green;
      }

      .nav-link{
        .badge-counter {
          right: 2rem;
          margin-top: 0.2rem;
          position: absolute;
          transform: scale(0.7);
          transform-origin: top right;
          font-size: 14px;
        }
      }
    }
  }
}

.countdown {
  position: absolute;
  top: 20px;
  right: 35px;
  color: $dark;
  font-weight: 500;

  &.ending {
    color: $red;
  }
}

.btn-circled {
  border-radius: 50px;
  padding: 10px 25px;
}

.offers {
  list-style: none;
  font-size: 14px;

  li {
    line-height: 40px;
    position: relative;
    padding-left: 40px;

    i {
      font-size: 2rem;
      margin-right: 10px;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
}

.blog-box {
  position: relative;

  .blog-img-box img {
    border-radius: 5px;
    box-shadow: 0 7px 12px rgba(0, 0, 0, 0.08);
  }
}

.single-blog {
  padding-top: 25px;
  position: relative;
}

.bg-light .single-blog {
  padding: 40px;
  text-align: center;
  border-bottom: 1px solid $light;
}

.blog-content {
  ul {
    margin-bottom: 10px;
  }
  a h3 {
    transition: all .3s ease 0s;
    margin: 15px 0px;
  }
  p {
    margin: 20px 0px;
  }

  &:hover a h3 {
    color: $blue;
  }

  h6 span {
    display: inline-block;
    padding-left: 10px;

    &:after {
      display: none;
    }
  }
}

.iti {
  display: block;
}

.order-details {
  .nav-tabs {
    > li > a {
      padding: 10px 25px;
    }
  }
  .status {
    position: absolute;
    right: 15px;
    top: 25px;
    font-size: 1.2rem;
  }

  .files p {
    font-weight: 400;
  }

  .label.label-default {
    background: #82c1bd;
  }

  .comments {
    background: #fcfcfc;
    padding: 15px;
    border-bottom: 1px solid #eee;
    position: relative;

    .time {
      position: absolute;
      top: 12px;
      right: 0;
      font-size: 11px;
      padding: 0 7px;
      color: $dark;;
    }
    .comment {
      padding: 5px;
      background: #f7f7f7;
    }
  }
  .actions .btn.btn-sm,
  .actions .btn-group-sm > .btn {
    width: 145px;
    padding: 10px 2px;
    margin-bottom: 8px;
  }
}

div.MultiFile-label {
  padding: 18px;
  background: #fdfdfd;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0px 0px 8px rgba(197, 197, 197, 0.6);
}

.MultiFile-title {
  width: 80%;
  display: inline-block;
}

.file-options.input {
  position: absolute;
  right: 40px;
  border-radius: 5px;
  height: 38px;
  width: 230px;
  top: 7px;
  border: 1px solid #eee;
  padding-left: 10px;
}

a.MultiFile-remove {
  position: absolute;
  right: 10px;
  background: #f90000;
  height: 20px;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  line-height: 20px;
  font-size: 11px;
  z-index: 10;
  text-decoration: none;
}

.message {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  border-left: 3px solid $white;

  &.unread {
    border-left: 3px solid $primary;

    .message-header {
      color: $blue;
      font-weight: 400;

      .label {
        background-color: $blue;
        color: $white;
      }
    }

    .date {
      color: $blue;
    }

  }

  .order-id {
    margin-top: 10px;
    display: block;
  }

  .message-header {
    display: block;
    text-decoration: none;
    color: $dark;
    padding: 5px 0;

    .label {
      font-size: 0.65em;
    }
  }

  .sender {
    width: 30%;
    display: inline-block;
    padding: 3px 0;
  }
  .subject {
    display: inline-block;
    width: 50%;
    padding: 3px 0;
  }
  .date {
    display: inline-block;
    text-align: right;
    width: 18%;
    padding: 3px 0;
    font-size: 12px;
    color: #aaa;
  }
  .message-body {
    padding: 5px 10px;
    background-color: #fbfbfb;
    font-size: 13px;
  }
}

.notifications {

  a {
    text-decoration: none;
  }

  .notification {
    padding: 8px 10px;
    display: block;
    position: relative;
    border-bottom: 1px solid $light;

    > a {
      display: block;
      color: $dark;
    }

    p {
      margin: 0;
      font-weight: inherit;
    }

    &.unread {
      > a {
        color: $primary;
      }
    }

    .time {
      font-size: 10px;
      margin-top: 5px;
      display: block;
      color: $dark;
    }

    .action {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

.wallet {
  .withdraw {
    text-align: right;
  }
  .balance {
    line-height: 60px;
  }
  .amount {
    font-size: 24px;
    color: #736c6c;
  }
  .payment-icon {
    text-align: center;
    font-size: 24px;
    padding-top: 10px;
  }
  .verification, .status, .actions {
    padding-top: 10px;
  }
  .payment-method {
    display: none;
  }
}

.table {
  thead {
    th {
      border: {
        top: none;
        bottom: none;
      }
      font-weight: 500;
    }
  }
  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background: rgba(247, 247, 249);
      }
    }
  }

  &.dataTable {
    font-size: 0.8rem;
    font-weight: 500;

    .dt-order-item {
      cursor: pointer;
    }
  }
}

.dataTables_wrapper {
  .dataTable th,
  .dataTable td {
    color: #6c7293;
  }
}

.table > tbody > tr > td,
.table > tbody > tr > th {
  word-wrap: break-word;
  white-space: initial;
}

.table > thead > tr > th > a:hover,
.table > tbody > tr > td > a:hover {
  text-decoration: none;
}

.profile {
  position: relative;
  padding: 10px 10px 0;

  .profile-photo {
    margin: 0 auto;
    width: 55px;
    height: 55px;
  }

  .profile-info {
    padding: 5px 25px 0;

    .profile-rating {
      .star {
        color: $light;
      }
    }
  }
}