@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');

@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/mixins";
@import "custom/variables";
@import "~bootstrap/scss/bootstrap";
@import "~boxicons/css/boxicons.css";
@import "~@icon/icofont/icofont.css";
@import "~intl-tel-input/build/css/intlTelInput.css";
@import "~select2/src/scss/core";
@import "~sweetalert2/src/sweetalert2";
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~aos/dist/aos';
@import "custom/functions";
@import "custom/mixins";
@import 'custom/components';
@import 'custom/utilities';
@import 'custom/vendors';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  font-family: "Poppins", sans-serif;
  color: #585a61;
  background-color: $body-bg;

  &.page{
    background-color: $white;
  }
}

a {
  color: $primary;

  &:hover {
    color: #7b94c9;
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/

.back-to-top {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  right: 15px;
  bottom: 15px;
  background: $primary;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;

  i {
    font-size: 24px;
    position: absolute;
    top: 7px;
    left: 8px;
  }

  &:hover {
    color: #fff;
    background: #748ec6;
    transition: background 0.2s ease-in-out;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  height: 72px;
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;

  &.header-scrolled {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }

  .logo {
    a {
      color: #47536e;
    }

    img {
      padding: 0;
      margin: 0;
      max-height: 50px;
      margin-right: 3px;
    }
  }
}

@media (max-width: 992px) {
  #header {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */

.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
  }

  a {
    display: block;
    position: relative;
    color: #47536e;
    padding: 8px 15px 12px 15px;
    transition: 0.3s;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .active > a, li:hover > a {
    color: $primary;
    text-decoration: none;
  }

  .get-started a {
    background: $primary;
    color: #fff;
    border-radius: 50px;
    margin: 0 15px;
    padding: 10px 25px;

    &:hover {
      background: darken($primary, 15%);
      color: #fff;
    }
  }

  .drop-down {
    ul {
      display: block;
      position: absolute;
      left: 0;
      top: calc(100% - 30px);
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      padding: 10px 0;
      background: #fff;
      box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
      transition: ease all 0.3s;
    }

    &:hover > ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }

    li {
      min-width: 180px;
      position: relative;
    }

    ul {
      a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: #233355;

        &:hover {
          color: $primary;
        }
      }

      .active > a, li:hover > a {
        color: $primary;
      }
    }

    > a:after {
      content: "\ea99";
      font-family: IcoFont;
      padding-left: 5px;
    }

    .drop-down {
      ul {
        top: 0;
        left: calc(100% - 30px);
      }

      &:hover > ul {
        opacity: 1;
        top: 0;
        left: 100%;
      }

      > a {
        padding-right: 35px;

        &:after {
          content: "\eaa0";
          font-family: IcoFont;
          position: absolute;
          right: 15px;
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down {
    ul {
      left: -90%;
    }

    &:hover > ul {
      left: -100%;
    }

    > a:after {
      content: "\ea9d";
    }
  }
}

/* Mobile Navigation */

.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 22px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;

  i {
    color: $primary;
  }
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
    position: relative;
    color: #47536e;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .active > a, li:hover > a {
    color: $primary;
    text-decoration: none;
  }

  .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 10px;
    position: absolute;
    right: 15px;
  }

  .active.drop-down > a:after {
    content: "\eaa1";
  }

  .drop-down {
    > a {
      padding-right: 35px;
    }

    ul {
      display: none;
      overflow: hidden;
    }

    li {
      padding-left: 20px;
    }
  }
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(35, 51, 85, 0.7);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;

  .mobile-nav {
    opacity: 1;
    visibility: visible;
  }

  .mobile-nav-toggle i {
    color: #fff;
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#hero {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 140px 0 100px 0;

  h1 {
    margin: 0 0 45px 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: #47536e;
  }

  h2 {
    color: #515f7d;
    margin-bottom: 50px;
    font-size: 18px;
    letter-spacing: 1px;
  }

  .download-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    display: inline-block;
    padding: 12px 25px 12px 45px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: $primary;
    position: relative;

    .light-text{
      color: #cdf4ec;
    }

    &:hover {
      background: darken($primary, 15%);
    }

    i {
      font-size: 20px;
      position: absolute;
      left: 18px;
      top: 14.5px;
    }

    + .download-btn {
      margin-left: 20px;
    }
  }
}

@media (max-width: 991px) {
  #hero {
    text-align: center;

    .download-btn + .download-btn {
      margin: 0 10px;
    }

    .hero-img {
      text-align: center;

      img {
        width: 60%;
      }
    }
  }
}

@media (max-width: 768px) {
  #hero {
    h1 {
      font-size: 28px;
      line-height: 36px;
    }

    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    .hero-img img {
      width: 70%;
    }
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f2f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;

  h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: $primary;
    font-family: "Poppins", sans-serif;
  }

  p {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
# App Features
--------------------------------------------------------------*/

.features .content {
  padding: 30px 0;

  .icon-box {
    margin-top: 25px;

    h4 {
      font-size: 20px;
      font-weight: 700;
      margin: 5px 0 10px 60px;
    }

    i {
      font-size: 48px;
      float: left;
      color: $primary;
    }

    p {
      font-size: 15px;
      margin-left: 60px;
    }
  }
}

@media (max-width: 991px) {
  .features .image {
    text-align: center;

    img {
      max-width: 80%;
    }
  }
}

@media (max-width: 667px) {
  .features .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Details
--------------------------------------------------------------*/

.details .content {
  + .content {
    margin-top: 100px;
  }

  h3 {
    font-weight: 700;
    font-size: 32px;
    color: #47536e;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-bottom: 10px;
    }

    i {
      font-size: 20px;
      padding-right: 4px;
      color: $primary;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/

.gallery {
  overflow: hidden;

  .owl-nav, .owl-dots {
    margin-top: 40px;
    text-align: center;
  }

  .owl-item {
    transition: 0.3s ease-in-out;
  }

  .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #eff2f8 !important;

    &.active {
      background-color: $primary !important;
    }
  }

  .gallery-carousel {
    padding-top: 30px;

    .owl-stage-outer {
      overflow: visible;
    }
  }
}

@media (min-width: 992px) {
  .gallery .gallery-carousel .center {
    border: 6px solid $primary;
    padding: 4px;
    background: #fff;
    z-index: 1;
    transform: scale(1.2);
    margin-top: 10px;
    border-radius: 25px;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/

.testimonials {
  .testimonial-wrap {
    padding-left: 50px;
  }

  .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 30px 60px;
    margin: 30px 15px;
    min-height: 200px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;

    .testimonial-img {
      width: 90px;
      border-radius: 10px;
      border: 6px solid #fff;
      position: absolute;
      left: -45px;
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0 5px 0;
      color: #111;
    }

    h4 {
      font-size: 14px;
      color: #999;
      margin: 0;
    }

    .quote-icon-left, .quote-icon-right {
      color: #e8ecf5;
      font-size: 26px;
    }

    .quote-icon-left {
      display: inline-block;
      left: -5px;
      position: relative;
    }

    .quote-icon-right {
      display: inline-block;
      right: -5px;
      position: relative;
      top: 10px;
    }

    p {
      font-style: italic;
      margin: 15px auto 15px auto;
    }
  }

  .owl-nav, .owl-dots {
    margin-top: 5px;
    text-align: center;
  }

  .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;

    &.active {
      background-color: $primary !important;
    }
  }
}

@media (max-width: 767px) {
  .testimonials {
    .testimonial-wrap {
      padding-left: 0;
    }

    .testimonial-item {
      padding: 30px;
      margin: 15px;

      .testimonial-img {
        position: static;
        left: auto;
      }
    }
  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/

.pricing {
  .row {
    padding-top: 40px;
  }

  .box {
    padding: 40px;
    margin-bottom: 30px;
    box-shadow: 0 0 30px rgba(73, 78, 92, 0.15);
    background: #fff;
    text-align: center;
  }

  h3 {
    font-weight: 300;
    margin-bottom: 15px;
    font-size: 28px;
  }

  h4 {
    font-size: 46px;
    color: $primary;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 25px;

    span {
      color: #bababa;
      font-size: 18px;
      display: block;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    color: #999;
    text-align: left;
    line-height: 20px;

    li {
      padding-bottom: 12px;
    }

    i {
      color: $primary;
      font-size: 18px;
      padding-right: 4px;
    }

    .na {
      color: #ccc;

      i {
        color: #ccc;
      }

      span {
        text-decoration: line-through;
      }
    }
  }

  .get-started-btn {
    background: $primary;
    display: inline-block;
    padding: 6px 30px;
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    transition: 0.3s;

    &:hover {
      background: darken($primary, 15%);
    }
  }

  .featured {
    z-index: 10;
    margin: -30px -5px 0 -5px;

    .get-started-btn {
      background: $primary;

      &:hover {
        background: darken($primary, 15%);
      }
    }
  }
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/

.faq .accordion-list {
  padding: 0 100px;

  ul {
    padding: 0;
    list-style: none;
  }

  li {
    + li {
      margin-top: 15px;
    }

    padding: 20px;
    background: #f2f5fa;
    border-radius: 4px;
    position: relative;
  }

  a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    outline: none;

    &:hover{
      color: darken($primary, 15%);
    }
  }

  .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: $info;
  }

  .icon-show, .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }

  p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }

  .icon-show {
    display: none;
  }

  a.collapsed {
    color: $dark;

    &:hover {
      color: $primary;
    }

    .icon-show {
      display: inline-block;
    }

    .icon-close {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact {
  .info {
    padding: 20px 40px;
    background: #f1f3f6;
    color: #47536e;
    text-align: center;
    border: 1px solid #fff;

    i {
      font-size: 48px;
      color: $info;
      margin-bottom: 15px;
    }

    h4 {
      padding: 0;
      margin: 0 0 10px 0;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
    }

    p {
      font-size: 15px;
    }
  }

  .php-email-form {
    width: 100%;

    label {
      font-family: "Poppins", sans-serif;
      margin-bottom: 5px;
      color: #8a8c95;
    }

    input, textarea {
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
    }

    input:focus, textarea:focus {
      background-color: $light;
    }

    input {
      padding: 20px 15px;
    }

    textarea {
      padding: 12px 15px;
    }

    button[type="submit"] {
      background: #fff;
      border: 2px solid $primary;
      padding: 10px 24px;
      color: $primary;
      transition: 0.4s;
      border-radius: 50px;
      margin-top: 5px;

      &:hover {
        background: $primary;
        color: #fff;
      }
    }
  }
}

@media (max-width: 1024px) {
  .contact .php-email-form {
    padding: 30px 15px 15px 15px;
  }
}

@media (max-width: 768px) {
  .contact .php-email-form {
    padding: 15px 0 0 0;
  }
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  padding: 0 0 30px 0;
  color: $dark;
  font-size: 14px;
  background: $light;

  .footer-newsletter {
    padding: 50px 0;
    background: #eff2f8;
    text-align: center;
    font-size: 15px;

    h4 {
      font-size: 24px;
      margin: 0 0 20px 0;
      padding: 0;
      line-height: 1;
      font-weight: 600;
      color: $primary;
    }

    form {
      margin-top: 30px;
      background: #fff;
      padding: 6px 10px;
      position: relative;
      border-radius: 50px;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
      text-align: left;

      input {
        &[type="email"] {
          border: 0;
          padding: 4px 8px;
          width: calc(100% - 100px);
        }

        &[type="submit"] {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          border: 0;
          background: none;
          font-size: 16px;
          padding: 0 20px;
          background: $primary;
          color: #fff;
          transition: 0.3s;
          border-radius: 50px;
          box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

          &:hover {
            background: #415f9d;
          }
        }
      }
    }
  }

  .footer-top {
    padding: 60px 0 30px 0;

    .footer-contact {
      margin-bottom: 30px;

      h4 {
        font-size: 22px;
        margin: 0 0 30px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        font-weight: 700;
        color: $primary;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: "Raleway", sans-serif;
        color: #8a8c95;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: $primary;
      position: relative;
      padding-bottom: 12px;
    }

    .footer-links {
      margin-bottom: 30px;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        i {
          padding-right: 2px;
          color: $dark;
          font-size: 18px;
          line-height: 1;
        }

        li {
          padding: 10px 0;
          display: flex;
          align-items: center;

          &:first-child {
            padding-top: 0;
          }
        }

        a {
          color: $dark;
          transition: 0.3s;
          display: inline-block;
          line-height: 1;

          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }
      }
    }

    .social-links a {
      font-size: 18px;
      display: inline-block;
      background: $primary;
      color: #fff;
      line-height: 1;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 50%;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;

      &:hover {
        background: #27282c;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .copyright {
    text-align: center;
    float: left;
    color: #47536e;
  }

  .credits {
    float: right;
    text-align: center;
    font-size: 13px;
    color: #47536e;
  }
}

@media (max-width: 768px) {
  #footer {
    .copyright, .credits {
      float: none;
      text-align: center;
      padding: 5px 0;
    }
  }
}